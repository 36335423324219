<template>
  <div class="wrap">
    <van-nav-bar :title="$t('newAdd.Withdraw')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
      <template #right>
        <button @click="handleLinkPage('withdraw-list')" class="nav_right_button">
          {{ $t("newAdd.Record") }}
        </button>
      </template>
    </van-nav-bar>
    <div class="content_wrap">
      <!-- name -->
      <template>
        <div class="picker_wrap" @click="showPicker = true">
          <div class="title">{{ bankInfo.name }}</div>
          <van-icon name="arrow" color="var(--theme)" />
        </div>
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker show-toolbar :columns="banks" @cancel="showPicker = false" @confirm="bankPickerConfirm"
            :value-key="`name`" :confirm-button-text="$t('Button.confirm')" :cancel-button-text="$t('Button.cancle')" />
        </van-popup>
      </template>
      <!-- bank -->
      <template v-if="bankInfo.open">
        <div class="title">{{ $t("newBank.usdt.chainType") }}</div>
        <div class="option">
          {{ `${bankInfo.bank || ""}/${bankInfo.open || ""}` }}
        </div>
      </template>
      <template v-else>
         <div class="title">{{ $t("newBank.usdt.bankName") }}</div>
        <div class="options">
          {{ bankInfo.bank || "" }}
        </div>
      </template>
      <!-- card -->
      <template>
        <div class="title">{{ bankInfo.open ? $t("newBank.usdt.depositAddress"):$t("newBank.usdt.bankCard") }}</div>
        <!-- <input type="text" v-model="bankInfo.card" disabled /> -->
        <textarea v-model="bankInfo.card" disabled cols="30" rows="10"></textarea>
      </template>
      <template>
        <div class="title">{{ $t("newBank.withdrawNum") }}</div>
        <div class="input_wrap">
          <input type="text" v-model="money" placeholder="0.00" />
          <div class="right">
            <div class="btn" @click="money = userInfo.money">
              {{ $t("newBank.AllOut") }}
            </div>
            <div class="unit">USDT</div>
          </div>
        </div>
      </template>
      <div class="balance">
        <span class="title">{{ $t("newBank.balance") }}:&nbsp;&nbsp;</span>
        <span class="value">{{ userInfo.money || "0.00" }}&nbsp;&nbsp;USDT</span>
      </div>
      <div class="handlingFee">
        <span class="title">{{ $t("newBank.handlingFee") }}:&nbsp;&nbsp;</span>
        <span class="value">{{ handlingFee || "0.00" }}&nbsp;&nbsp;USDT</span>
      </div>
      <div class="actualAmount">
        <span class="title">{{ $t("newBank.actualAmount") }}:&nbsp;&nbsp;</span>
        <span class="value">{{ actualAmount || "0.00" }}&nbsp;&nbsp;USDT</span>
      </div>
      <div class="submit" @click="Withdrawal">
        {{ $t("newBank.usdt.submit") }}
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USER_BANK_INFO, WITHDRAW, getUserInfoNew, extract } from "@/api";
import Cookies from "js-cookie";
import { NavBar, Icon, Picker, Popup, Toast, Dialog } from "vant";
import Vue from "vue";
import { isObject, count, getUserInfo, getToken } from "@/utils";
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Picker);
Vue.use(Popup);
export default {
  data() {
    return {
      sms: "",
      country: Cookies.get("language"),
      money: "",
      bankInfo: {},
      userInfo: {},
      isshowDialog: false,
      editValue: "",
      money_not: 0,
      paypassword: this.$route.query.paypassword
        ? this.$route.query.paypassword
        : true,
      withdrawType: "bank",
      rateData: {},
      showPicker: false,
      banks: [],
      amount: "",
      handlingFeeData: [],
    };
  },
  computed: {
    money_can() {
      return this.userInfo.money_not > 0 ? 0 : this.userInfo.money;
    },
    actualAmount() {
      let amount = 0;
      if (Number(this.money)) {
        if (String(this.rateData.value).indexOf("%") === -1) {
          amount = (this.money - this.rateData.value) / this.rateData.rate;
        } else {
          const fixedNum = 100000000;
          amount =
            Math.round(
              ((this.money *
                (1 -
                  String(this.rateData.value).substring(
                    0,
                    String(this.rateData.value).indexOf("%")
                  ) /
                  100)) /
                this.rateData.rate) *
              fixedNum
            ) / fixedNum;
        }
      }
      return amount > 0 ? amount : 0;
    },
    handlingFee() {
      const item =
        this.handlingFeeData.find((a) => a.name === this.bankInfo.open) || {};
      return item.value == "0" ? "0.00" : item.value;
    },
  },
  created() {
    this.getUserBankInfo();
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      try {
        const { data } = await getUserInfoNew();
        this.userInfo = data;
      } catch (error) { }
    },
    bankPickerConfirm(value, index) {
      this.bankInfo = value;
      this.showPicker = false;
    },
    // 提交提现
    async onSubmit() {
      this.isshowDialog = false;
      if (this.editValue.trim().length !== 4 && this.verConfig.needPaypass) {
        Toast(this.$t("newAdd.EnterValidPayPwd"));
      } else {
        const { data } = await extract({
          money: this.money,
          // paypass: this.editValue,
          bankid: this.bankInfo.id,
        });
        Toast(data.msg);
        this.editValue = "";
        this.$router.push("/login");
      }
    },
    confirm() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      WITHDRAW({
        money: this.money,
        // paypass: this.editValue,
        bankid: this.bank_id,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            Toast(r.data.msg);
            getUserInfoNew();
            this.$router.back();
          } else {
            Toast.clear();
            Toast(r.data.msg);
            // setTimeout(() => {
            //   this.$router.replace({ name: 'login' })
            // }, 1000)
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    Withdrawal() {
      // if (this.money_can === 0) {
      //   Toast('The withdrawal balance is zero')
      //   return
      // }
      // if (this.money === 0) {
      //   Toast('Please fill in the amount')
      //   return
      // }
      // this.isshowDialog = true
      this.confirm();
    },
    allHandler() {
      // this.money = this.money_can
      this.money = this.userInfo.money;
      // if (this.money_can !== 0) {
      //   this.money = this.money_can
      // }
    },
    getUserBankInfo() {
      // const bankInfo = JSON.parse(this.$cookie.get("selectBankInfo"));
      // if (bankInfo) {
      //   this.bankInfoInit(bankInfo);
      //   return;
      // }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USER_BANK_INFO()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            if (isObject(r.data.data) && count(r.data.data) > 0) {
              this.bankInfoInit(r.data.data[0]);
              const data = r.data.data
              data.forEach(item => {
                if (item.open) {
                  item.name += '(' + this.$t('newBank.usdt.wallet') + ')'
                  // return {...item,name: item.name + }
                } else {
                  item.name += '(' + this.$t('newBank.usdt.bank') + ')'
                  // return {...item,name: item.name + '('+ this.$t('newAdd.BankCard') + ')'}
                }
              });
              this.banks = data;
            } else {
              Toast.clear();
              // language-ch 您尚未设置银行卡，是否前往设置？
              Dialog.confirm({
                title: this.$t("newAdd.warmTitle"),
                confirmButtonText: this.$t("Button.confirm"),
                cancelButtonText: this.$t("Button.cancle"),
                message: this.verConfig.needBank
                  ? this.$t("newAdd.NotHaveCard")
                  : this.$t("newAdd.NotHaveWallet"),
                // 'You have not set up your bank card, do you want to go to the setting?',
              })
                .then((res) => {
                  this.itemClick("BankCardAdd");
                })
                .catch((err) => {
                  this.$router.back();
                });
            }
          } else {
            Toast.clear();
            // Toast("拉取银行信息失败");
            Toast(this.$t("newAdd.GetBankInfoFail"));
            // this.$router.back()
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    bankInfoInit(data) {
      this.bank = data.bank;
      this.bank = data.bank;
      this.bank_id = data.id;
      this.bank_name = data.name;
      this.bank_cardl4 = data.card_l4;
      this.bankInfo = data;
      if (!!data.open && data.open !== "bank") {
        this.withdrawType = "coin";
        this.$api.getWithdrawRate((res) => {
          this.handlingFeeData = res;
          const openRateData = res.find(
            (item) => item.name.toUpperCase() == data.open
          );
          if (!!openRateData) {
            this.rateData = openRateData;
            return;
          }
          const bankRateData = res.find(
            (item) => item.name.toUpperCase() == data.bank
          );
          if (!!bankRateData) {
            this.rateData = bankRateData;
          }
        });
      } else {
        this.withdrawType = "bank";
      }
    },
    itemClick(page) {
      this.$router.push({ name: page });
    },
    handleLinkPage(path) {
      if (getToken()) {
        this.$router.push(`/${path}`);
      } else {
        this.$toast($t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  @theme: var(--nav-background);
  background: @theme;
  min-height: 100vh;

  .nav_right_button {
    padding: calc(6rem / 16) calc(12rem / 16);
    background: var(--btnColor);
    // background: var(--bg);
    color: var(--newbtnsizeColor);
    border-radius: 4px;
    font-size: calc(14rem / 16);
    border: none;
  }

  .content_wrap {
    @paddingHor: calc(12rem / 16);
    margin: calc(18rem / 16) calc(12rem / 16);
    // background: #fff;
    background: var(--bg);
    padding: calc(12rem / 16) calc(10rem / 16);
    border-radius: calc(8rem / 16);

    .picker_wrap {
      padding: 0 calc(10rem / 16);
      height: calc(40rem / 16);
      background: @theme;
      border-radius: calc(5rem / 16);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:active {
        opacity: 0.8;
      }

      .title {
        color: var(--theme);
        font-size: calc(14rem / 16);
      }

      .icon {}
    }

    >.title {
      font-size: calc(12rem / 16);
      margin-top: calc(24rem / 16);
      color: var(--sizeColor);
    }

    .option {
      padding: 0 calc(10rem / 16);
      line-height: calc(32rem / 16);
      border-radius: 99px;
      background: var(--theme);
      // color: #fff;
      color: var(--btnsizeColor);
      min-width: calc(60rem / 16);
      text-align: center;
      font-size: calc(14rem / 16);
      display: inline-block;
      margin-top: calc(8rem / 16);
    }
    .options{
      padding: 0 calc(10rem / 16);
      line-height: calc(40rem / 16);
      border-radius:calc(5rem / 16);
      background: var(--nav-background);
      color: var(--theme);
      flex: 1;
      font-size: calc(14rem / 16);
      margin-top: calc(8rem / 16);
    }

    >input {
      background: @theme;
      width: calc(100% - @paddingHor);
      border: none;
      border-radius: calc(4rem / 16);
      height: calc(36rem / 16);
      line-height: calc(36rem / 16);
      text-indent: calc(12rem / 16);
      margin-top: calc(6rem / 16);
    }

    >textarea {
      background: @theme;
      width: calc(100% - @paddingHor - calc(20rem / 16));
      border: none;
      border-radius: calc(4rem / 16);
      height: calc(80rem / 16);
      padding: calc(12rem / 16);
      margin-top: calc(6rem / 16);
      color: var(--sizeColor);
    }

    .input_wrap {
      display: flex;
      background: @theme;
      width: calc(100% - @paddingHor + 4rem / 16 - 24rem / 16);
      margin-top: calc(6rem / 16);
      height: 36rem / 16;
      align-items: center;
      justify-content: space-between;
      border-radius: calc(4rem / 16);
      padding: 0 calc(12rem / 16);

      >input {
        background: transparent;
        border: 0;
        line-height: calc(36rem / 16);
        // text-indent: calc(12rem / 16);
        flex-grow: 1;
        color: var(--sizeColor);
      }

      .right {
        display: flex;
        font-size: calc(14rem / 16);

        .btn {
          color: var(--theme);

          &:active {
            opacity: 0.7;
          }
        }

        .unit {
          margin-left: calc(12rem / 16);
          color: var(--sizeColor);
        }
      }
    }

    .balance,
    .handlingFee,
    .actualAmount {
      margin-top: calc(10rem / 16);

      .title {
        font-size: calc(12rem / 16);
        color: var(--sizeColor);
      }

      .value {
        // color: #f00;
        color: var(--theme);
        font-size: calc(14rem / 16);
      }
    }

    .submit {
      font-size: calc(16rem / 16);
      background: var(--theme);
      // color: #fff;
      color: var(--newbtnsizeColor);
      text-align: center;
      line-height: calc(40rem / 16);
      width: calc(100% - @paddingHor + 4rem / 16);
      margin: calc(60rem / 16) 0;
      margin-bottom: calc(30rem / 16);
      border-radius: calc(4rem / 16);

      // margin: calc(50rem / 16) auto 0;
      &:active {
        opacity: 0.7;
      }
    }
  }
}

[class*=van-hairline]::after {
  border-bottom: 1px solid var(--nav-background);
}
</style>